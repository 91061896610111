.baccarat-road {
  // max width and overflow x scroll
  max-width: 80vw;
  overflow-x: auto;
  overflow-y: hidden;
}

.live-result-item,
.road-cell {
  background-color: #525252;
  border-radius: 2px;
  font-size: 12px !important;
  height: 17px;
  margin-right: 4px;
  margin-top: 4px;
  padding: 1px;

  width: 17px;

  .banker {
    background-color: #ca0200
  }

  .banker,
  .player {
    border-radius: 50%;
    color: #fff;
    height: 100%;
    line-height: 17px;
    text-align: center;
    width: 100%
  }

  .player {
    background-color: #0cb9f2
  }

  .tie {
    background-color: #24bc00;
    border-radius: 50%;
    color: #fff;
    height: 100%;
    line-height: 17px;
    text-align: center;
    width: 100%
  }

  .non {
    height: 100%;
    width: 100%
  }
}

.live-result-table {

  height: 130px;
  margin: auto;
  max-width: 260px;
  max-width: unset;
  width: fit-content;
  display: grid;
  grid-template-rows: repeat(6, 1fr);
  grid-auto-flow: column;
  column-gap: 1px;
  /* Set the gap between columns */
  row-gap: 1px;
  /* Set the gap between rows */


  max-width: unset;
  width: fit-content;
  


  &.bigeyeboy,
  &.smallroad {
    .road-cell-circle- {

      &B,
      &P {
        height: 12px;
        border-radius: 50%;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        width: 12px;
        background-color: transparent;
      }
    }
  }

  &.bigeyeboy {
    .road-cell-circle- {
      &B {
        border: 2px solid red;

      }

      &P {
        border: 2px solid blue;
      }
    }
  }

  &.smallroad {
    .road-cell-circle- {
      &B {
        border: 2px solid red;
        background-color: red;

      }

      &P {
        border: 2px solid blue;
        background-color: blue;
      }
    }
  }

  &.cockroachroad {
    .road-cell {
      position: relative;
    }

    .road-cell-circle-B::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom right,
          transparent 0%,
          transparent calc(50% - 2px),
          red 50%,
          transparent calc(50% + 2px),
          transparent 100%);

      border-radius: inherit;
    }

    .road-cell-circle-P::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom right,
      transparent 0%,
      transparent calc(50% - 2px),
      blue 50%,
      transparent calc(50% + 2px),
      transparent 100%);
      
      border-radius: inherit;
    }
  }
}

@media (max-width: 479px) {
  .waving-loading {
    display: none;
  }
  .live-result-table {
    height: 120px;
    width: 240px
  }

  .live-result-item,
  .road-cell {
    font-size: 8px !important;
    height: 14px;
    margin-top: 4px;
    padding: 0;

    width: 14px;

    .banker,
    .player,
    .tie {
      line-height: 12px
    }
  }
}
@media (max-width: 767px) {
    .footer-logo {
        padding: 8px 24px !important;
    }
}


@media (max-width: 568px) {

    .footer-logo {
        padding: 8px !important;
    }
}

.footer-logo {
    padding: 12px;
}
// _variables.scss

:root {
    --min-height-header: 60px;
    --min-height-footer: 100px;
    --text-link-color: rgb(81,108,68);
    --bg-root: rgb(01,28,18);
    --bg-root-transparent: rgba(01,28,18,0.8);
    --bg-card-dashboard: rgb(31,58,48);
    --bg-table-dashboard: rgb(11,38,28);
    --bg-header-dashboard: rgb(7, 19, 15);
    --bg-partner-dashboard: rgb(31,58,48);
    --bg-platform-dashboard: rgb(7, 19, 15);
    --bg-next-round-prediction-dashboard: rgb(07, 19, 15);
    --bg-button-dashboard: rgb(21,48,38);
    --bg-table-card-selected-dashboard: rgb(02, 19, 12);
    --bg-linear-gradient-color: rgb(42, 59, 52);
    --bg-linear-gradient-color-darken: rgb(22, 39, 32);
    // --bg-partner-dashboard: #26202e;
    // --bg-card-dashboard: #18151e;
    // --bg-formula-even: #18151e;
    // --bg-formula-odd: #201c28;
    --bg-formula-even: rgb(31,58,48);
    --bg-formula-odd: rgb(51,78,68);
    --active-green-color: #76ef48;
    --filter-form-text-color: #888;
    --filter-form-arrow-color: hsla(0, 0%, 100%, .2);
    --filter-form-search-field-color: hsla(0, 0%, 100%, .1);
    --height-header-dashboard: 120px;





    --scrollbar-hover: rgb(11,38,28); 
    --scrollbar: rgb(31,58,48);
    --scrollbar-track: rgb(71,98,88);
  }
  
.tool-bar-header,.tg-header {
    background-color: var(--bg-card-dashboard);
    gap: 8px;
}

.tool-bar-header {
    // make it a grid with 2 columns, the first one with a fixed width
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
}

.sign-in-btn {
    // background-color: var(--bg-button-dashboard);
    // color: white;
    // border: none;
    // border: 1px solid lightblue;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
}


@media (max-width: 479px) {
    .sign-in-btn {
        padding: 0 0.5rem !important;
        height: 32px;
        font-size: small;
    }
}
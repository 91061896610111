@tailwind base;
@tailwind components;
@tailwind utilities;


.grecaptcha-badge {
  visibility: hidden !important;
}

#root {
  min-height: 100vh;
  height: fit-content;
  // background-color: var(--bg-root);
  overflow-x: hidden;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


#dashboardBody {
  position: relative;
  min-height: calc(100vh - 160px);

  // z-index: -2;
  // pointer-events: none;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // background: var(--bg-root-transparent);
    filter: blur(1px);
    width: 100vw;
    height: 100%;
    z-index: -2;
    /* Ensure the blur is above the background but below the content */
    pointer-events: none;
    /* Ensure it doesn't block interactions */
    background-image: url('./assets/bg2.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--bg-root-transparent);
    filter: blur(1px);
    width: 100vw;
    height: 100%; 
    pointer-events: none;
    z-index: -1;
  }

}

*::-webkit-media-controls-panel {
  display: none !important;
  -webkit-appearance: none;
}

*::-webkit-media-controls-play-button {
  display: none !important;
  -webkit-appearance: none;
}

*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

.custom-video-controls {
  z-index: 2147483647;
}

@font-face {
  font-family: 'Anta';
  src: url('./fonts/Anta-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@import 'styles/_variables.scss';
@import '~bootstrap/scss/bootstrap';



// add media query for .dashboard-content-D
@media (max-width: 767px) {
  .dashboard-content-D {
    width: 100% !important;
  }
}

@media (max-width: 640px) {
  .percentage-container {
    width: calc(100% + 48px);
  }
  
}


.left-title {
  color: #e3d2ff;
  font-size: small;

}

#dashboard-body {
  min-height: calc(100vh - var(--height-header-dashboard) - var(--min-height-footer));
}


.dashboard-sidebar-D {
  width: 220px;
  background-color: var(--bg-partner-dashboard);
}

.dashboard-menu-M {
  background-color: var(--bg-partner-dashboard);
  width: 100%;
  overflow-x: auto;
}

.dashboard-menu-M__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #e2d1fe;
  padding: 12px;
  border-right: 1px solid #ffffff3b;
  min-width: 142px;

  >div {
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.selected {
    // background-color: #3a2864;
    // background-image: linear-gradient(135deg, rgba(0, 0, 0, .22), rgba(255, 255, 255, 0)), url('../../assets/background-green.svg');
    background-image: linear-gradient(135deg, rgba(0, 0, 0, .22), var(--bg-linear-gradient-color));
  }

  &.inactive {
    filter: grayscale(100%);
    cursor: not-allowed;
    // add a blur effect
    // backdrop-filter: blur(10px);
  }
}

.dashboard-menu-M__item .container-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40px;
}

.dashboard-menu-M__item-tive {
  font-weight: bold;
  background-color: #3a2665;
}

.dashboard-content-D {
  width: calc(100% - 220px);
}

.dashboard_red_note {
  background-color: #b93f4e;
  border-radius: 5px;
}

.dashboard_red_note .left {
  width: max-content;
}

.dashboard_red_note .center {
  width: max-content;
  min-width: 155px;
  font-size: 16px;
  font-weight: bold;
}


.left-dash {
  min-height: calc(100vh - var(--min-height-header) - var(--min-height-footer));
}

.item-selected,
.formula:hover,
.left-dash-menu-item:hover {
  // background-color: #3a2864 !important;
  // background-image: linear-gradient(135deg, rgba(0, 0, 0, .22), rgba(255, 255, 255, 0)), url('../../assets/background-green.svg');
  background-image: linear-gradient(135deg, rgba(0, 0, 0, .22), rgb(02, 19, 12));
  color: #cfab61;
}

.list-formula {
  background-color: var(--bg-card-dashboard);
  height: 266px;
  overflow-y: auto;
}

.table-preiction .ant-table-tbody>tr.ant-table-row:hover>td,
.table-preiction .ant-table-tbody>tr>td.ant-table-cell-row-hove {
  background-color: transparent;
}

.table-prediction-container::-webkit-scrollbar,
.list-formula::-webkit-scrollbar,
.dashboard-menu-M::-webkit-scrollbar {
  width: 4px;
  height: 12px;
}

.list-table-dashboard::-webkit-scrollbar {
  height: 10px;
}

.table-prediction-container::-webkit-scrollbar-track,
.list-table-dashboard::-webkit-scrollbar-track,
.list-formula::-webkit-scrollbar-track,
.dashboard-menu-M::-webkit-scrollbar-track {
  background: var(--scrollbar-track);
}

.table-prediction-container::-webkit-scrollbar-thumb,
.list-table-dashboard::-webkit-scrollbar-thumb,
.list-formula::-webkit-scrollbar-thumb,
.dashboard-menu-M::-webkit-scrollbar-thumb {
  background: var(--scrollbar);
  border-radius: 15px;
  border: 2px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
}

.table-prediction-container::-webkit-scrollbar-thumb:hover,
.list-table-dashboard::-webkit-scrollbar-thumb:hover,
.list-formula::-webkit-scrollbar-thumb:hover,
.dashboard-menu-M::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-hover);
  border: 1px solid rgba(0, 0, 0, 0);
}

.list-formula .formula:nth-child(even) {
  background-color: var(--bg-formula-even);
}

.list-formula .formula:nth-child(odd) {
  background-color: var(--bg-formula-odd);
}

.circle-bg-pink {
  background-color: #a0015e;
}

.search-text-color {
  width: max-content;
}

.collapse-card-custom {
  background-color: var(--bg-card-dashboard);
}



.formula-item {
  color: #e3d2ff;
}

.formula-item-selected {
  color: #cfab61;
}

.table-card {
  // background-image: url('../../assets/poke-card.png');
  // background-size: contain;
  background-image: url('../../assets/poke-card2.png');
  background-size: 200%;
  background-repeat: no-repeat;
  background-position: bottom 0px right -80px;
  /* Offset the background image more to the right */
  background-color: var(--bg-table-dashboard);
  border-radius: 5px;
  width: 120px;
  position: relative;
  z-index: 1;

  // animation: moveInBottom 5s ease-out;
  // animation-fill-mode: backwards;

  &-selected {

    background-color: var(--bg-table-card-selected-dashboard);
    position: relative;
    overflow: hidden;
    border: none;
    outline: none;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .6), 0 2px 10px rgba(255, 255, 255, 0.064);

    &::after,
    &:hover::after {
      content: '';
      position: absolute;
      background: url('../../assets/poke-card2.png') var(--bg-table-card-selected-dashboard);
      background-size: 200%;
      background-repeat: no-repeat;
      background-position: bottom 0px right -80px;
      /* Offset the background image more to the right */
      // background-color: var(--bg-table-card-selected-dashboard);
      border-radius: 5px;
      z-index: -1;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      left: 2px;
      top: 2px;
      border-radius: 10px;
    }



    &::before {

      content: '';
      position: absolute;
      background: lightgreen;
      width: 200%;
      height: 200%;
      z-index: -2;
      left: 50%;
      top: 50%;
      transform-origin: 0 0;
      animation: rotate 3s infinite linear;
    }
  }

  &:link,
  &:visited {
    transition: all .2s;
    position: absolute;
  }

  &::after {
    // background-color: var(--bg-table-dashboard);
    // content: "";
    // display: inline-block;
    // height: 100%;
    // width: 100%;
    // border-radius: 5px;
    // position: absolute;
    // top: 0;
    // left: 0;
    // z-index: -1;
    // transition: all .4s;
  }

  &:hover {
    // background-color: #3a2864
    // transform: translateY(-3px);

    // background-image: linear-gradient(135deg, rgba(0, 0, 0, .22), rgba(255, 255, 255, 0)), url('../../assets/background-green.svg');
    // background-image: linear-gradient(135deg, rgba(0, 0, 0, .22), var(--bg-linear-gradient-color));
    // add box shadow and animation

    box-shadow: 0 10px 20px rgba(0, 0, 0, .2);
    cursor: pointer;

    &::after {
      // transform: scaleX(1.1) scaleY(1.1);
      // opacity: 0;
    }

  }

  &:active {
    // transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }
}

.button-select-table-card {
  border-radius: 5px;
  width: 120px;
  height: auto;
  background-color: var(--bg-linear-gradient-color-darken);
  border-color: var(--bg-linear-gradient-color);
  color: white;
  text-transform: uppercase;
  font-weight: 500;
  padding: 4px 15px;
}

.button-select-table-card:hover,
.button-select-table-card:focus,
.button-select-table-card:active {
  background-color: var(--bg-linear-gradient-color);
  border-color: var(--bg-linear-gradient-color);
  color: white;
}

.button-select-table-card:hover {
  background-color: var(--bg-linear-gradient-color);
  border-color: var(--bg-linear-gradient-color);
  color: white;
}

.text-smaller {
  font-size: smaller !important;
}


.donutchart-track {
  fill: transparent;
  stroke: #fff;
  stroke-width: 26
}

.donutchart-indicator {
  fill: transparent;
  stroke: #009688;
  stroke-width: 26;
  stroke-dasharray: 0 10000;
  transition: stroke-dasharray .3s ease
}

.donutchart {
  border-radius: 50%;
  display: block;
  // padding: 4px;
}

.donutchart-text {
  fill: #fff
}

.donutchart-text-val {
  font-size: 22px
}

.donutchart-text-percent {
  font-size: 14px
}

.donutchart-text-label {
  font-size: small
}

.circle-bg-pink {
  background-color: #a0015e;
}

.circle-bg-blue {
  background-image: linear-gradient(180deg, #0db6ed, #09428f);
}

.circle-bg-green {
  background-image: linear-gradient(180deg, #28d309, #1d550a);
}

.circle-bg-red {
  background-image: linear-gradient(180deg, #df0900, #a80500);
}

.circle-bg-orange {
  background: linear-gradient(#ff7d00, #000)
}


.prediction-sec .hit-rate {
  color: #d9d9d9 !important;
  font-size: 18px
}

.prediction-sec-bg {
  // background-color: #261a42
  background-color: var(--bg-linear-gradient-color)
}

.prediction-circle {
  border-radius: 5px;
  padding: 12px 0
}

.prediction-circle .name {
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase
}

.prediction-circle .hide-next-round {
  color: #868686;
  font-size: 8px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase
}

.prediction-circle .name-banker {
  color: #e50800
}

.prediction-circle .name-player {
  color: #0cb9f2
}

.prediction-circle .name-tie {
  color: #24bc00
}

.prediction-circle .name-cal {
  color: #ff7d00
}

.prediction-circle .name {
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
}

.text-small {
  font-size: small !important;
}

.collapse-header {
  color: #fff;
  font-size: 18px
}

.text-green {
  color: var(--active-green-color);
}


.dashboard-menu-M {
  background-color: var(--bg-partner-dashboard);
  overflow-x: auto;
  width: 100%
}

.dashboard-menu-M__item {
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-right: 1px solid #ffffff3b;
  color: #e2d1fe;
  display: flex;
  flex-direction: column;
  min-width: 142px;
  padding: 12px
}

.dashboard-menu-M__item .container-icon {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 40px;
  justify-content: center
}

.table-card-road-maps {
  gap: 10px;
}



.table-card-selected-bg {
  box-shadow: 0 2px 10px rgba(0, 0, 0, .6), 0 2px 10px rgba(255, 255, 255, 0.064);
  padding: 24px !important;
  border-radius: 8px;
  // width: 95%;
  // flex: 0 0 49%;
}

.dashboard-platform-list {
  background-color: var(--bg-platform-dashboard);

  .ant-menu-item-selected {
    background-color: var(--bg-linear-gradient-color);
    color: #cfab61;
  }
}

@media (min-width: 1200px) {
  .table-card-selected-bg {
    flex: 0 0 49%;

    &.table-card-road-maps {
      flex: 0 0 100%;
    }
  }
}

.prediction-collapse {
  &.inactive div {
    filter: blur(2px) grayscale(100%);
    /* 将所有子div的内容转为灰度 */
    cursor: not-allowed;
    // backdrop-filter: blur(10px);
  }
}

.border-container {
  position: relative;
  overflow: hidden;
  border: none;
  outline: none;
  z-index: 1;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, .6), 0 2px 10px rgba(255, 255, 255, 0.064);


  &::before {

    content: '';
    position: absolute;
    background: lightgreen;
    z-index: -2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // clip-path: polygon(50% 50%, 0 0, 100% 0);
    /* Creates a fan shape */
    // animation: rotate 3s infinite linear;
    animation: clippath 3s infinite linear;

  }


  @keyframes clippath {

    0%,
    100% {
      clip-path: inset(0 0 95% 0);
    }

    25% {
      clip-path: inset(0 0 0 95%);
    }

    50% {
      clip-path: inset(95% 0 0 0);
    }

    75% {
      clip-path: inset(0 95% 0 0);
    }
  }



  &::after {
    content: '';
    position: absolute;
    background: #000;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    left: 2px;
    top: 2px;
    border-radius: 10px;
    z-index: -1;
    background-color: var(--bg-next-round-prediction-dashboard);
  }


}

@keyframes rotate {
  to {
    transform: rotate(1turn)
  }
}


.wave-text {}

.wave-text span {
  display: inline-block;
  animation: wave 1s infinite;
  animation-timing-function: ease-in-out;
}

@keyframes wave {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }
}

$base-delay: 0s;
$increment: 0.1s;

.wave-text {
  span {
    @for $i from 1 through 20 {

      // Adjust 20 to the maximum number of characters you expect
      &:nth-child(#{$i}) {
        animation-delay: $base-delay + $i * $increment;
      }
    }
  }
}

.next-round-prediction {
  // background-color: #f8f9fa;
  padding: 10px;
  margin: 20px 0;

  // border: 1px solid #dee2e6;
  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: var(--bg-next-round-prediction-dashboard);
    z-index: 10000;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    padding: 16px;
    margin: 0;
    max-width: 100% !important;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
    padding-left: 5% !important;
    padding-right: 5% !important;
  }
}

// .fixed-placeholder {
//   height: 190px;
// }
// @import '~bootstrap/scss/bootstrap';
#dashboard-header {
    min-height: var(--min-height-header);
    // background-color: #000000;
    background-color: var(--bg-header-dashboard);
    color: #e4e4e5;
    border-bottom: 1px solid #27212f;
  }
  

.dropdown-user-manager {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.dropdown-user-manager .label {
  width: max-content;
}

.dropdown-user-manager__overlay {
  padding: 0;
  background-color: #303435;
  border-radius: 8px;
  border: 2px solid #484848;
  margin-top: 8px;
}
.dropdown-user-manager__overlay .ant-dropdown-menu-item {
  padding: 10px 18px;
  color: white;
}
.dropdown-user-manager__overlay .ant-dropdown-menu-item:hover {
  background-color: #555a5a;
}
.dropdown-user-manager__overlay .ant-dropdown-menu-item:first-child {
  border-bottom: 2px solid #484848;
}

.dropdow_menu_header {
  background-color: #000000;
  min-width: 260px;
  width: 100%;
  color: white;

  .ant-collapse-content-box {
    padding: 0 !important;
  }
}


.btn-change-pwd {
  // padding: 8px 15px !important;
  height: 48px;
}

.locale-flag {
  height: 20px;
  width: 20px;
  object-fit: cover;
  border-radius: 50%;
}
// Double
.reloadDouble {
    border-color: transparent white;
    border-radius: 50%;
    border-style: solid;
    border-width: .125em;
    height: 1.5em;
    margin: .25em;
    width: 1.5em;

    &:before,
    &:after {
        border-style: solid;
        content: '';
        display: block;
        position: absolute;
        width: 0;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }

    &:after {
        border-color: transparent transparent transparent white;
        border-width: .3125em 0 .3125em .5em;
        top: -.3125em;
        left: .0625em;
    }

    &:before {
        border-color: transparent white transparent transparent;
        border-width: .3125em .5em .3125em 0;
        bottom: -.3125em;
        right: .0625em;
    }
}

// Single
.reloadSingle {
    height: 2em;
    padding: .25em;
    width: 2em;

    &:before,
    &:after {
        color: rgb(108, 117, 125);
        content: '';
        display: block;
    }

    &:before {
        border-color: transparent white white white;
        border-radius: 50%;
        border-style: solid;
        border-width: .125em;
        height: 1.5em;
        width: 1.5em;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    &:after {
        border-color: transparent transparent transparent white;
        border-style: solid;
        border-width: .3125em 0 .3125em .5em;
        height: 0;
        position: absolute;
        top: 0;
        left: 50%;
        width: 0;
    }
}

.reloadDouble,
.reloadSingle {
    display: inline-block;
    position: relative;
    cursor: pointer;
    color: rgb(108, 117, 125);
    &.loading {
        -webkit-animation: rotate 0.5s linear infinite;
        animation: rotate 0.5s linear infinite;
    }
}

@-webkit-keyframes rotate {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #0a0013 !important; */
  /* background-color: var(--bg-linear-gradient-color-darken) !important; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.custom-cursor,
.custom-cursor-shadow {
  position: absolute;
  border-radius: 50%;
  /* pointer-events: none;  */
  transition: transform 0.1s ease-out;
}

.custom-cursor {
  width: 20px;
  height: 20px;
  background-color: rgba(255, 255, 255, 0.8);
  border: 2px solid rgba(0, 0, 0, 0.8);
  /* transform: translate(-50%, -50%); */
  z-index: 1000;
}

.custom-cursor-shadow {
  width: 60px;
  height: 60px;
  background-color: rgba(0, 0, 0, 0.1);
  /* transform: translate(-50%, -50%); */
  z-index: 999;
}

.landing-container {

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        // background: var(--bg-root-transparent);
        filter: blur(1px);
        width: 100vw;
        height: 100%;
        z-index: -2;
        /* Ensure the blur is above the background but below the content */
        pointer-events: none;
        /* Ensure it doesn't block interactions */
        background-image: url('../../assets/bg2.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top;
    }

    color: white;

    .landing-logo {
        position: fixed;
        left: 20px;
        top: 10px;
        background-color: rgba(38, 33, 47, 0.8);
        border-radius: 16px;
        padding: 4px 16px;
        height: 40px;
    }

    .landing-title {
        text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.8);
    }

    .landing-subtitle {
        text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
        max-width: 90vw;
    }

    .landing-poster {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
    }

    .landing-poster-container {
        width: 100%;
        height: 100vh;
        max-height: 174vw;
        object-fit: contain;
        background-image: url('../../assets/landing_bg.jpeg');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;


        .tg-btn {
            z-index: 20;
            position: absolute;
            top: 41%;
            left: calc(50vw - min(15vh, 30vw));
            transform: translateX(-50%);
            width: 30vh;
            max-width: 60vw;
            aspect-ratio: 368/119;
            background-image: url('../../assets/landing_telegram_btn.png');
            background-size: cover;
        }

        .line-btn {
            z-index: 20;
            position: absolute;
            top: 51%;
            left: calc(50vw - min(15vh, 30vw));
            transform: translateX(-50%);
            width: 30vh;
            max-width: 60vw;
            aspect-ratio: 368/115;
            background-image: url('../../assets/landing_line_btn.png');
            background-size: cover;
        }

        .tg-btn,
        .line-btn {
            cursor: pointer;
            transition: transform 0.3s ease-in-out;
            animation: scaleAnimation 1s infinite;
            // border: 1px solid transparent;
            transition: border-color 0.3s ease-in-out;

            &:hover {
                border-color: rgba(255, 255, 255, 0.8);
            }
        }

        @media (max-width: 733px) {

            .tg-btn,
            .line-btn {
                // width: 20vh;
            }
        }

        @keyframes scaleAnimation {
            0% {
                transform: scale(0.95);
            }

            50% {
                transform: scale(1.03);
            }

            100% {
                transform: scale(0.95);
            }
        }
    }

    .landing-login-button {
        &:hover {
            animation: bounceAnimation 0.5s infinite;
        }

        @keyframes bounceAnimation {
            0% {
                transform: scale(1);
            }

            50% {
                transform: scale(1.1);
            }

            100% {
                transform: scale(1);
            }
        }

        &-text {
            margin-top: 3px;
            margin-right: 3px;
            background: linear-gradient(to bottom right, rgba(51, 51, 51, 0.8), rgba(0, 0, 0, 0.8));
            transition: background-color 0.3s ease;
            cursor: pointer;

            &:hover {
                animation: colorChangeAnimation 0.3s forwards;
            }

            @keyframes colorChangeAnimation {
                to {
                    background-color: rgba(0, 51, 0, 0.8); // deep dark green
                }

                from {
                    background-color: rgba(51, 51, 51, 0.8); // original gradient color
                }
            }
        }
    }

    .canvas-animate {
        transition: transform 0.3s ease-in-out;

        animation: scaleAnimation 1s infinite;

        &-2 {
            animation: scaleAnimation 1s infinite;

        }
    }

    .canvas-normal {
        transition: transform 0.3s ease-in-out;
        transform: scale(1); // Ensures normal size with transition
    }


}

.my-landing {

    &-container {
        background-color: #1E1E1E;
        padding: 16px;
    }

    &-logo {
        filter: brightness(200%) contrast(100%) saturate(100%) blur(0px) hue-rotate(0deg);
    }

    &-btn {
        width: auto;
        // min-width: 200px;
        font-family: "Anta", Sans-serif;
        color: white;
        font-weight: 500;
        padding: 16px 36px;
        border-radius: 12px;
        animation: pulse 1.5s infinite;
        text-wrap: nowrap;
        &:hover {
            animation: elementor-animation-pulse 1s infinite;
            animation-timing-function: linear;
        }

        &.blue {
            box-shadow: 0px 2px 10px 0px #34CDFF;

            &:hover {
                color: #5FD3FF;
            }
        }

        &.green {
            box-shadow: 0px 2px 10px 0px #6AFF34;
            padding: 16px 50px;
            &:hover {
                color: #6AFF34;
            }
        }


        @keyframes pulse {

            from,
            to {
                transform: scale3d(1, 1, 1)
            }

            50% {
                transform: scale3d(1.05, 1.05, 1.05)
            }
        }

        @keyframes elementor-animation-pulse {
            25% {
                transform: scale(1.1)
            }
        
            75% {
                transform: scale(0.9)
            }
        }
    }
    &-games {
        > img {
            // max-width: 140px;
        }
    }
}